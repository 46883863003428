import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const FeatureSectionComponent = (props) => {
  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    };

    setResponsive(updatedResponsive);
  }, []);

  return (
    <>
      <section className={props.isPlacement ? `` : `featured-section`}>
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2
                  style={
                    props.isPlacement
                      ? {
                          textAlign: "center",
                          marginBottom: "30px",
                        }
                      : {}
                  }
                  className={props.isPlacement ? `title ` : `title text-white`}
                >
                  {props.data}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                items={5}
                className="owl-theme fetured_slide owl-loaded owl-drag"
                loop
                margin={20}
                autoplay={true}
                dots={false}
                responsive={responsive}
              >
                {props.dataList
                  ? props.dataList.map((featuredIn, index) => (
                      <div key={index} className="item">
                        <a href={featuredIn.redirectionURL} target="_blank">
                          <div className="featured-inner">
                            <img src={featuredIn.imagePath} alt={index} />
                          </div>
                        </a>
                      </div>
                    ))
                  : ""}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureSectionComponent;
